import {
    CASH_UPS_FETCH_REQUEST,
    CASH_UPS_FETCH_SUCCESS,
    CASH_UPS_FETCH_FAILURE,
    CASH_UPS_SAVE_REQUEST,
    CASH_UPS_SAVE_SUCCESS,
    CASH_UPS_SAVE_FAILURE,
    EMPTY_CASH_UPS,
    CLEAR_ERRORS
} from "./types";

export default {
    fetch({ commit }, date) {
        commit(CASH_UPS_FETCH_REQUEST);
        return window.axios
            .get(`/cash-ups`, { params: { date } })
            .then(response => {
                commit(CASH_UPS_FETCH_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(CASH_UPS_FETCH_FAILURE, error);
                throw error;
            });
    },
    saveCashIn({ commit }, data) {
        commit(CASH_UPS_SAVE_REQUEST);
        return window.axios.post(`/cash-ups/cash-in`, data)
            .then(response => {
                commit(CASH_UPS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(CASH_UPS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(CASH_UPS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    savePettyTaken({ commit }, data) {
        commit(CASH_UPS_SAVE_REQUEST);
        return window.axios.post(`/cash-ups/petty-taken`, data)
            .then(response => {
                commit(CASH_UPS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(CASH_UPS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(CASH_UPS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    saveActualOut({ commit }, data) {
        commit(CASH_UPS_SAVE_REQUEST);
        return window.axios.post(`/cash-ups/actual-out`, data)
            .then(response => {
                commit(CASH_UPS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(CASH_UPS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(CASH_UPS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    empty({ commit }) {
        commit(EMPTY_CASH_UPS);
    },
    clearErrors({ commit }) {
        commit(CLEAR_ERRORS);
    }
};
