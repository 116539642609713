import {
  CASH_UPS_FETCH_REQUEST, CASH_UPS_FETCH_SUCCESS, CASH_UPS_FETCH_FAILURE,
  CASH_UPS_SAVE_REQUEST, CASH_UPS_SAVE_SUCCESS, CASH_UPS_SAVE_FAILURE,
  EMPTY_CASH_UPS,
  CLEAR_ERRORS,
} from './types'

export default {
  [CASH_UPS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [CASH_UPS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [CASH_UPS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [CASH_UPS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [CASH_UPS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [CASH_UPS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_CASH_UPS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  }
}
