<template>
	<router-view />
</template>

<script>
import cashUpModule from "../../store/cash-ups"

export default {
	beforeCreate() {
		this.$store.registerModule("cash-ups", cashUpModule)
	},
	destroyed() {
		this.$store.unregisterModule("cash-ups")
	},
}
</script>
