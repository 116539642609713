export default {
  isLoading ({ loading }) {
    return loading
  },
  isSaving ({ saving }) {
    return saving
  },
  single ({ single }) {
    return single
  },
  errors ({ validationErrors }) {
    return validationErrors
  },
}
